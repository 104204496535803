import { on } from 'delegated-events';
import { createFocusTrap, FocusTrap } from 'focus-trap';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import moveFocus from '../../../javascripts/utils/move-focus';
import collapse from '../../../javascripts/utils/collapse';
import onLostFocus from '../../../javascripts/utils/on-lost-focus';
import invisibleFocus from '../../../javascripts/utils/invisible-focus';

let currentLostFocusHandler: CallableFunction | null = null;
let focusTrap: FocusTrap | null = null;
let openNavigation: HTMLElement | null = null;

on('click', '.header__navigation-link[aria-controls]', async (event) => {
  const { currentTarget: $trigger } = event;

  event.preventDefault();

  const { isOpen, $target } = await collapse($trigger);

  // Focus on opening
  if (isOpen) {
    moveFocus($target);
    currentLostFocusHandler = onLostFocus($target, () =>
      collapse($trigger, false),
    );
  } else if (currentLostFocusHandler) {
    currentLostFocusHandler();
  }
});

on('click', '.js-toggle-search', async (event) => {
  const { currentTarget: $trigger } = event;
  const $search = $trigger.closest<HTMLElement>('.header__search');
  if (!$search) {
    return;
  }

  event.preventDefault();

  const { isOpen } = await collapse($trigger);

  // On open
  if (isOpen) {
    focusTrap = createFocusTrap($search, {
      onDeactivate() {
        $trigger.click();
      },
      initialFocus: 'input',
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: false,
    });

    focusTrap.activate();
  } else if (focusTrap) {
    focusTrap.deactivate({
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onDeactivate: () => {},
    });

    focusTrap = null;
  }

  // On close
  if (!isOpen) {
    invisibleFocus($trigger);
  }
});

on('click', '.js-open-navigation', async (event) => {
  const { currentTarget: $trigger } = event;

  event.preventDefault();

  const { $target } = await collapse($trigger, true);
  openNavigation = $trigger;

  $target.classList.add('header__navigation--open');

  focusTrap = createFocusTrap($target, {
    initialFocus: '.header__navigation-link',
    clickOutsideDeactivates: false,
    returnFocusOnDeactivate: true,
    onActivate() {
      const $firstLink = $target.querySelector<HTMLAnchorElement>(
        '.header__navigation-link',
      );
      if ($firstLink) {
        invisibleFocus($firstLink);
      }
    },
  });

  focusTrap.activate();

  const $navigationItems = $target.querySelector<HTMLElement>(
    '.header__navigation-items',
  );

  if ($navigationItems) {
    disableBodyScroll($navigationItems);
  }
});

on('click', '.js-close-navigation', async (event) => {
  event.preventDefault();

  if (!openNavigation) {
    return;
  }

  const { $target } = await collapse(openNavigation, false);

  $target.classList.remove('header__navigation--open');
  $target.hidden = false;

  if (focusTrap) {
    focusTrap.deactivate();
    focusTrap = null;
  }

  const $navigationItems = $target.querySelector<HTMLElement>(
    '.header__navigation-items',
  );
  if ($navigationItems) {
    enableBodyScroll($navigationItems);
  }

  invisibleFocus(openNavigation);
  openNavigation = null;
});
