import whenReady from '../../../javascripts/utils/when-ready';

whenReady().then(() => {
  const $$teaserSlider = document.querySelectorAll('.teaser-slider');
  $$teaserSlider.forEach(($teaserSlider) => {
    // Get navigation buttons
    const $leftButton = $teaserSlider.querySelector(
      '.teaser-slider__button--left',
    );
    const $rightButton = $teaserSlider.querySelector(
      '.teaser-slider__button--right',
    );

    // Get scroll container
    const $scroller = $teaserSlider.querySelector('.teaser-slider__teasers');

    // Get second item with margin
    const $secondItem = $teaserSlider.querySelector(
      '.teaser-slider__teaser:nth-child(2)',
    );

    // Toggle navigation buttons
    const toggleNavigationButtons = () => {
      const hideThreshold = 50;
      const { offsetWidth, scrollWidth, scrollLeft } = $scroller;
      $leftButton.disabled = scrollLeft < hideThreshold;
      $rightButton.disabled =
        scrollLeft > scrollWidth - offsetWidth - hideThreshold;
    };

    // Update navigation buttons on scroll and init
    $scroller.addEventListener('scroll', toggleNavigationButtons, {
      passive: true,
    });
    toggleNavigationButtons();

    // Move scroll container on click
    const clickHandler = (direction) => (event) => {
      event.preventDefault();

      const { width, marginLeft } =
        $secondItem.currentStyle || window.getComputedStyle($secondItem);

      $scroller.scrollLeft +=
        (parseInt(width, 10) + parseInt(marginLeft, 10)) * direction;
    };

    $leftButton.addEventListener('click', clickHandler(-1));
    $rightButton.addEventListener('click', clickHandler(1));
  });
});
