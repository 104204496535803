import whenReady from '../../../javascripts/utils/when-ready';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipeDynamicCaption from "photoswipe-dynamic-caption-plugin";

whenReady().then(() => {
  const lightbox = new PhotoSwipeLightbox({
    gallery: '.gallery',
    children: 'a',
    showHideAnimationType: 'fade',
    showAnimationDuration: 200,
    hideAnimationDuration: 200,
    showHideOpacity:true,
    getThumbBoundsFn:false,
    pswpModule: () => import('photoswipe')
  });

  const captionPlugin = new PhotoSwipeDynamicCaption(lightbox, {
    // Plugins options, for example:
    type: 'auto',
    captionContent: '.image__caption'
  });

  lightbox.init();



});
