import { on } from 'delegated-events';
import whenReady from '../../../javascripts/utils/when-ready';
import elementIndex from '../../../javascripts/utils/element-index';

const moveStage = ($stage, value = 0, relative = true) => {
  const currentSlide = parseInt($stage.dataset.currentSlide, 10) || 1;
  const $$slide = $stage.querySelectorAll('.stage__slide');
  const nextSlide = relative ? currentSlide + value : value;

  // No moving, if it's only one slide
  if ($$slide.length === 1) {
    return;
  }

  // Out of range
  if (nextSlide === 0 || nextSlide > $$slide.length) {
    return;
  }

  // Update scroll track
  const $track = $stage.querySelector('.stage__track');
  const $slide = $$slide[nextSlide - 1];
  $track.scrollLeft = $slide.offsetLeft;
  $stage.dataset.currentSlide = nextSlide; // eslint-disable-line no-param-reassign

  // Update buttons
  const $leftButton = $stage.querySelector('.stage__navigation-button--left');
  const $rightButton = $stage.querySelector('.stage__navigation-button--right');

  $leftButton.disabled = nextSlide === 1;
  $rightButton.disabled = nextSlide === $$slide.length;

  // Update indicators
  const $$indicator = $stage.querySelectorAll('.stage__navigation-indicator');
  $$indicator.forEach(($indicator, index) => {
    // eslint-disable-next-line no-param-reassign
    $indicator.disabled = index + 1 === nextSlide;
  });
};

whenReady().then(() => {
  const $$track = document.querySelectorAll('.stage__track');
  $$track.forEach(($track) => {
    // eslint-disable-next-line no-param-reassign
    $track.scrollLeft = 0;
  });
});

window.addEventListener(
  'resize',
  () => {
    const $$slider = document.querySelectorAll('.stage');
    $$slider.forEach(($slider) => moveStage($slider));
  },
  false,
);

on('keydown', '.stage__track', (event) => {
  if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
    const $slider = event.target.closest('.stage');
    moveStage($slider, event.key === 'ArrowLeft' ? -1 : 1);
  }
});

on('click', '.stage__navigation-button--left', (event) => {
  const $slider = event.target.closest('.stage');
  moveStage($slider, -1);
});

on('click', '.stage__navigation-button--right', (event) => {
  const $slider = event.target.closest('.stage');
  moveStage($slider, 1);
});

on('click', '.stage__navigation-indicator', (event) => {
  const $indicator = event.target.closest('.stage__navigation-indicator');
  const $slider = $indicator.closest('.stage');
  const value = parseInt($indicator.dataset.index, 10);

  moveStage($slider, value, false);
});

on('mousedown', '.stage__slide a', (event) => {
  event.leftentDefault();
});

on('focusin', '.stage__slide', (event) => {
  const $slide = event.target.closest('.stage__slide');
  const $stage = $slide.closest('.stage');
  const value = elementIndex($slide);

  moveStage($stage, value + 1, false);
});

let startPosition = null;

on('touchstart', '.stage', (event) => {
  const { currentTarget: $stage } = event;
  startPosition = event.touches[0]?.clientX;

  const onMove = (moveEvent) => {
    const { clientX } = moveEvent?.touches[0] ?? 0;
    const diff = startPosition - clientX;

    if (diff > 100) {
      $stage.removeEventListener('touchmove', onMove);
      moveStage($stage, 1, true);
    } else if (diff < -100) {
      $stage.removeEventListener('touchmove', onMove);
      moveStage($stage, -1, true);
    }
  };

  const onEndOrCancel = () => {
    $stage.removeEventListener('touchmove', onMove);
    $stage.removeEventListener('touchend', onEndOrCancel);
    $stage.removeEventListener('touchcancel', onEndOrCancel);
    startPosition = null;
  };

  if (startPosition) {
    $stage.addEventListener('touchmove', onMove);
    $stage.addEventListener('touchend', onEndOrCancel);
    $stage.addEventListener('touchcancel', onEndOrCancel);
  }
});
