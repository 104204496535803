import { focusable, isFocusable } from 'tabbable';
import invisibleFocus from './invisible-focus';

const moveFocus = ($target: HTMLElement): void => {
  if (isFocusable($target)) {
    invisibleFocus($target);
  } else {
    const $$focusable = focusable($target);

    if ($$focusable.length > 0) {
      invisibleFocus($$focusable[0]);
    }
  }
};

export default moveFocus;
