import MicroModal from 'micromodal';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { on } from 'delegated-events';
import moveFocus from '../../../javascripts/utils/move-focus';
import whenReady from '../../../javascripts/utils/when-ready';

const openFlyout = ($flyout: HTMLElement, $target?: HTMLElement) => {
  const $content = $flyout.querySelector<HTMLDivElement>('.flyout__content');

  if (!$content) {
    return;
  }

  MicroModal.show($flyout.id, {
    onShow() {
      disableBodyScroll($content);
      moveFocus($content);
    },
    onClose() {
      enableBodyScroll($content);
      moveFocus($target ?? document.body);
    },
  });
};

on('click', '.js-flyout', (event) => {
  const { currentTarget: $target } = event;
  const { flyout: flyoutId } = $target.dataset;
  const $flyout = flyoutId ? document.getElementById(flyoutId) : null;

  if (!$flyout) {
    return;
  }

  event.preventDefault();
  openFlyout($flyout, $target);
});

whenReady().then(() => {
  const $openFlyout = document.querySelector<HTMLElement>(
    '.flyout[aria-hidden="false"]',
  );

  if ($openFlyout) {
    openFlyout($openFlyout);
  }
});

on('click', '.flyout__backdrop, .flyout__close-button', (event) => {
  const { currentTarget: $target } = event;
  const $flyout = $target.closest<HTMLElement>('.flyout');

  if ($flyout) {
    event.preventDefault();
    MicroModal.close($flyout.id);
  }
});
