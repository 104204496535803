import whenReady from '../../../javascripts/utils/when-ready';

whenReady().then(async () => {
  const $$calendar = document.querySelectorAll<HTMLElement>(
    '.calendar__calendar',
  );

  if ($$calendar.length > 0) {
    const { default: initCalender } = await import(
      /* webpackChunkName: "calendar" */ './calendar'
    );

    $$calendar.forEach(($calendar) => {
      try {
        const configuation = JSON.parse($calendar.dataset.config ?? '');
        initCalender($calendar, configuation);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    });
  }
});
