export type CollapseState = {
  isOpen: boolean;
  $target: HTMLElement;
};

const collapse = (
  $trigger: HTMLElement,
  forcedState?: boolean,
): Promise<CollapseState> => {
  const currentlyOpen = $trigger.getAttribute('aria-expanded') === 'true';
  const controls = $trigger.getAttribute('aria-controls');

  if (!controls) {
    return Promise.reject();
  }

  const $target = document.getElementById(controls);

  if (!$target) {
    return Promise.reject();
  }

  // Toggle state
  const isOpen = forcedState ?? !currentlyOpen;
  $trigger.setAttribute('aria-expanded', isOpen ? 'true' : 'false');
  $target.hidden = !isOpen;

  return Promise.resolve({
    isOpen,
    $target,
  });
};

export default collapse;
